import React, { useContext } from "react";
import { Toolbar, Button, Typography, makeStyles } from "@material-ui/core";
import { AppContext } from "../AppContextProvider";
import { LeadDialogContext } from "./ViewLeadDialog";
import BlueButton, { GreenButton, RedButton } from "../components/ColorButton";
import { STATUS, USERTYPES } from "../Constants";

const useStyles = makeStyles(() => ({
  grow: {
    flexGrow: 1,
  },
}));

const ToolbarBottom = () => {
  const { mobileView, state } = useContext(AppContext);
  const { summary, editMode, action, lead } = useContext(LeadDialogContext);
  const classes = useStyles();
  return (
    <Toolbar style={{ minHeight: 80 }}>
      <div style={{ marginLeft: mobileView ? 0 : 16 }}>
        {summary && (
          <>
            {summary.title && (
              <Typography variant="h6">{summary.title}</Typography>
            )}
            {summary.remarks && (
              <Typography variant="body2">{summary.remarks}</Typography>
            )}
            {summary.subtitle && (
              <Typography variant="caption">{summary.subtitle}</Typography>
            )}
          </>
        )}
      </div>
      <div className={classes.grow} />
      {!editMode &&
        state.user &&
        [USERTYPES.KYC].includes(state.user.EmpUtype) &&
        lead &&
        (lead.status === STATUS.COMPLETED ||
          lead.status === STATUS.ESIGN_PENDING ||
          lead.status === STATUS.REJECTED) && (
          <BlueButton
            onClick={action("move-to-ongoing")}
            style={{ marginRight: 8 }}
          >
            Move back to Step
          </BlueButton>
        )}
      {editMode && (
        <Button
          color="secondary"
          onClick={action("discard")}
          style={{ marginRight: 8 }}
        >
          Discard
        </Button>
      )}
      {editMode && (
        <Button
          color="inherit"
          onClick={action("save")}
          style={{ marginRight: 8 }}
        >
          Save
        </Button>
      )}
      {!editMode &&
        state.user &&
        [
          USERTYPES.KYCHO,
          USERTYPES.HO,
          USERTYPES.KYC,
          USERTYPES.BRANCH,
          USERTYPES.FRAN,
          USERTYPES.RM,
        ].includes(state.user.EmpUtype) &&
        summary.btnEdit && (
          <Button
            color="inherit"
            onClick={action("edit")}
            style={{ marginRight: 8 }}
          >
            Edit
          </Button>
        )}
      {!editMode &&
        state.user &&
        [USERTYPES.KYCHO, USERTYPES.KYC].includes(state.user.EmpUtype) &&
        summary.btnReject && (
          <RedButton onClick={action("reject")} style={{ marginRight: 8 }}>
            Reject
          </RedButton>
        )}
      {!editMode &&
        state.user &&
        [USERTYPES.KYCHO, USERTYPES.KYC].includes(state.user.EmpUtype) &&
        summary.btnApprove && (
          <GreenButton onClick={action("approve")}>Approve</GreenButton>
        )}
      {!editMode &&
        state.user &&
        [USERTYPES.KYCHO, USERTYPES.KYC].includes(state.user.EmpUtype) &&
        lead.status === STATUS.APPROVED &&
        lead.can_activate && (
          <GreenButton onClick={action("activate")}>Activate</GreenButton>
        )}
      {!editMode &&
        lead &&
        (lead.retake_ipv === 0 ||
          lead.retake_photo === 0 ||
          lead.status === STATUS.ESIGN_REQUESTED) &&
        state.user &&
        [
          USERTYPES.KYCHO,
          USERTYPES.HO,
          USERTYPES.KYC,
          USERTYPES.BRANCH,
          USERTYPES.FRAN,
          USERTYPES.RM,
        ].includes(state.user.EmpUtype) && (
          <Button color="inherit" onClick={action("status")}>
            Check Status
          </Button>
        )}
      {!editMode &&
        lead &&
        (lead.status === STATUS.ESIGN_PENDING ||
          lead.status === STATUS.ESIGN_REQUESTED) &&
        !(lead.retake_ipv === 0 || lead.retake_photo === 0) &&
        state.user &&
        [
          USERTYPES.KYCHO,
          USERTYPES.HO,
          USERTYPES.KYC,
          USERTYPES.BRANCH,
          USERTYPES.FRAN,
          USERTYPES.RM,
        ].includes(state.user.EmpUtype) && (
          <Button color="inherit" onClick={action("request_esign")}>
            Request E-Sign
          </Button>
        )}
    </Toolbar>
  );
};

export default ToolbarBottom;
