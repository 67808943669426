import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { FormHelperText, Grid, Radio, RadioGroup } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  sectionHeading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
  },
  subheading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "left",
  },
  dialogContent: {
    paddingTop: 0,
  },
  formControl: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 14,
  },
}));

const CustomRadio = withStyles({
  root: {
    color: "transparent", // Hide the default radio circle
    display: "none",
    "&$checked": {
      color: "transparent", // Hide the circle even when checked
    },
    "&$checked + $label": {
      backgroundColor: "#c8e6c9", // Background color when checked
      color: "#fff", // Text color when checked
    },
    "&$root:hover": {
      backgroundColor: "#c8e6c9", // Hover effect for the whole radio button area
    },
    "&$root:focus": {
      outline: "none",
    },
  },
  checked: {}, // Keep it here to allow custom checked styles
})((props) => <Radio color="default" {...props} />);

const styles = {
  buttonStyle: (checked) => ({
    border: `2px solid ${checked ? "#c8e6c9" : "#ccc"}`, // Outline
    borderRadius: "8px", // Rounded corners for button look
    padding: "10px 20px", // Padding inside the button
    margin: "0 10px", // Space between the buttons
    cursor: "pointer",
    backgroundColor: checked ? "#c8e6c9" : "#fff", // Background when checked or not
    color: checked ? "#fff !important" : "#000", // Text color depending on state
    transition: "background-color 0.3s ease", // Smooth transition for hover/checked
  }),
};

const DialogTitle = (props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ paddingRight: 24 }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function MoveBackToStepDialog(props) {
  const { steps } = props;
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const classes = useStyles();

  React.useEffect(() => {
    if (props.open) {
      setErrorMessage(undefined);
      setOpen(props.open);
    } else {
      setOpen(props.open);
    }
  }, [props.open]);

  const handleClose = () => {
    props.close();
    setSelectedOption(null);
    setOpen(false);
    setErrorMessage(undefined);
  };

  const handleSave = () => {
    if (isEmpty(selectedOption)) {
      setErrorMessage("Select step to move");
    } else {
      props.save(!isEmpty(selectedOption) ? selectedOption : null);
    }
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <Dialog
      fullWidth={props.fullWidth !== undefined ? props.fullWidth : true}
      maxWidth={props.maxWidth !== undefined ? props.maxWidth : "lg"}
      onClose={(event, reason) =>
        reason !== "backdropClick" &&
        reason !== "escapeKeyDown" &&
        handleClose()
      }
      aria-labelledby="customized-dialog-title"
      disableEscapeKeyDown
      open={open}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        classes={classes}
      >
        <Typography variant="h6" component="div">
          Move Lead back to Step
        </Typography>
      </DialogTitle>
      <DialogContent dividers style={{ paddingTop: 10 }}>
        <Grid container spacing={1}>
          {/* <Grid item xs={12} lg={9}>
						{
							dropdodwnOptions && dropdodwnOptions.length > 0 && (
								<ItemSelection
									required={required || false}
									label='Remarks'
									error={error}
									helperText={errorMessage || undefined}
									value={selectedOptions || null}
									multiple
									disableCloseOnSelect
									optionLabel='value'
									options={dropdodwnOptions}
									renderOption={(option, { selected }) => (
										<li {...props}>
											<Checkbox
												checked={selected}
											/>
											{option.value}
										</li>
									)}
									style={{ zIndex: 500, marginTop: 0, marginLeft: 0 }}
									formClass={classes.formAutoClass}
									selected={(options) => {
										setSelectedOptions(options);
									}} />
							)
						}
					</Grid> */}
          <Grid item xs={12} lg={9}>
            {!isEmpty(steps) && (
              <RadioGroup
                value={selectedOption}
                onChange={handleChange}
                row={false}
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                {steps.map((step, i) => (
                  <FormControlLabel
                    key={step.step_name}
                    value={step.step_name}
                    control={<CustomRadio />}
                    label={step.label}
                    style={styles.buttonStyle(
                      selectedOption === step.step_name
                    )}
                  />
                ))}
              </RadioGroup>
              // <FormControl component="fieldset" className={classes.formControl}>
              // 	<FormLabel component="legend">Select Step to move</FormLabel>
              // 	<FormGroup>
              // 		{
              // 			documentList.map((d, i) => (
              // 				<FormControlLabel
              // 					key={i}
              // 					control={<Checkbox defaultChecked={d.rejected} onChange={handleChange} name={d.id} />}
              // 					label={d.label}
              // 				/>
              // 			))
              // 		}
              // 	</FormGroup>
              // </FormControl>
            )}
            {errorMessage ? (
              <FormHelperText error>{errorMessage}</FormHelperText>
            ) : null}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button autoFocus onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

MoveBackToStepDialog.prototype = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  maxWidth: PropTypes.any,
  fullWidth: PropTypes.bool,
};
